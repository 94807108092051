<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :accountId="$route.params.accountId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard', params: { realm: this.$route.params.realmId } }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list', params: { realm: this.$route.params.realmId } }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'realm-dashboard', params: { accountId: this.$route.params.accountId, realmId: this.$route.params.realmId } }">Realm</router-link>
                </p>
                </v-col>
            </v-row> -->

            <v-row justify="center" class="py-5 px-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                <v-card tile elevation="2">
                    <v-toolbar flat short color="white">
                        <v-toolbar-title>LoginShield Setup</v-toolbar-title>
                        <v-spacer/>
                        <v-progress-circular indeterminate color="indigo" size="24" width="2" v-if="isEnabledCheckPending" class="mr-0"></v-progress-circular>
                        <v-tooltip top v-if="isEnabledCheckComplete && (!loginshieldRealmId || !isLoginShieldEnabled)">
                            <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="orange--text"/>
                                </span>
                            </template>
                            <span>Action required</span>
                        </v-tooltip>
                        <v-tooltip top v-if="isEnabledCheckComplete && loginshieldRealmId && isLoginShieldEnabled">
                            <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    <font-awesome-icon :icon="['fas', 'check']" class="blue--text"/>
                                </span>
                            </template>
                            <span>Ready</span>
                        </v-tooltip>
                    </v-toolbar>
                    <template v-if="isEnabledCheckComplete && loginshieldRealmId">
                        <v-card-text class="text-start">
                            <template v-if="isLoginShieldEnabled">
                                <p class="font-weight-light text-start">
                                    LoginShield is enabled for this authentication realm.
                                </p>

                                <v-checkbox
                                    :input-value="isAutoUpdateEnabled"
                                    readonly
                                    color="indigo"
                                    @click="toggleAutoUpdate"
                                    >
                                <template v-slot:label>
                                    <span>Automatically update LoginShield with changes to realm</span>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <span style="margin-left: 8px">
                                            <font-awesome-icon :icon="['fas', 'question-circle']" class="blue--text" v-on="on"/>
                                            </span>
                                        <!-- <a
                                            target="_blank"
                                            href="/about/privacy"
                                            @click.stop
                                            v-on="on"
                                        >Privacy Policy</a> -->
                                        </template>
                                        When this setting is enabled, any changes to the realm display name, logo image, or login URL will be automatically updated at LoginShield so you don't have to repeat the same changes there.
                                    </v-tooltip>
                                </template>
                                </v-checkbox>

                            </template>
                            <template v-if="!isLoginShieldEnabled">
                                <p class="font-weight-light text-start">
                                    LoginShield is currently disabled for this authentication realm.
                                </p>
                                <!-- <v-alert dense type="warning" border="left">
                                    <template v-slot:prepend>
                                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="white--text"/>
                                    </template>
                                    <span class="ml-2">LoginShield is currently disabled</span>
                                </v-alert> -->
                                <v-btn elevation="4" class="blue white--text" @click="enableLoginShield">
                                    Enable LoginShield
                                </v-btn>
                            </template>
                        </v-card-text>
                    </template>

                    <template v-if="isEnabledCheckComplete && !loginshieldRealmId">
                        <v-card-text class="text-start">
                            <p class="font-weight-light text-start">
                                When you setup LoginShield, users can protect their accounts from phishing and password attacks. Individual users activate LoginShield for their own account from their authentication settings.
                            </p>
                            <p class="text-overline">
                                What is LoginShield?
                            </p>
                            <p class="font-weight-light text-start">
                                LoginShield is a one-tap, password-less login system with strong multi-factor authentication. LoginShield is the world's only authentication system with built-in protection against credential-theft phishing attacks.
                            </p>
                            <p class="text-overline">
                                Next steps
                            </p>
                            <p class="font-weight-light text-start">
                                Tap "continue" to setup LoginShield for this authentication realm.
                            </p>
                            <v-btn tile elevation="4" class="indigo white--text" @click="redirectToLoginShieldForSetup">
                                <span>Continue</span>
                            </v-btn>
                         </v-card-text>
                        <!-- <v-card-actions v-if="!isLoginShieldEnabled">
                            <v-spacer/>
                            <v-spacer/>
                        </v-card-actions> -->
                    </template>
                </v-card>

                <v-expansion-panels tile v-if="isEnabledCheckComplete && loginshieldRealmId" :value="0" class="mt-4">
                    <v-expansion-panel v-if="loginshieldRealmId">
                        <v-expansion-panel-header>
                            Advanced
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p class="font-weight-light text-start">
                                Realm ID: {{loginshieldRealmId}}
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!--
                            <p class="mb-0 pb-0">
                                <a href="#" @click="dialogDisableLoginShield = true">How to disable LoginShield for this authentication realm</a>
                            </p>
                            <v-dialog v-model="dialogDisableLoginShield" max-width="600">
                                <v-card elevation="4" class="pa-5">
                                    <v-card-text class="text-h6 pa-0 pb-3">Disable LoginShield</v-card-text>
                                    <v-row justify="center" class="pt-3">
                                        <v-col cols="12">
                                            <div class="font-weight-light text-body-1">
                                                <p>There are two ways to disable LoginShield:</p>
                                                <p>First, you can disable LoginShield by making it unavailable as a login mechanism. You can later re-enable LoginShield by simply returning here and switching it back on.</p>
                                                <p>Second, you can disable LoginShield by deleting the LoginShield configuration. You can later re-enable LoginShield by tapping the "Activate LoginShield" button. As long as the LoginShield realm has not been deleted, it will be automatically re-activated for users when you do this.</p>
                                                <p>Either way, when you disable LoginShield users will login with passwords they previously set, and access recovery is available in case they forgot their passwords.</p>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-btn elevation="4" class="red white--text" @click="editRealm">
                                            Reset
                                        </v-btn>
                                        <v-btn text class="grey--text" @click="dialogDisableLoginShield = false">Cancel</v-btn>
                                    </v-row>
                                </v-card>
                            </v-dialog>
                    -->

                    <v-expansion-panel v-if="loginshieldRealmId">
                        <v-expansion-panel-header>
                            Other
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-checkbox
                                :input-value="isLoginShieldEnabled"
                                readonly
                                color="indigo"
                                @click="dialogToggleEnabled = true"
                                >
                            <template v-slot:label>
                                <span style="margin-left: 4px">Make LoginShield available to users</span>
                            </template>
                            </v-checkbox>
                            <v-dialog v-model="dialogToggleEnabled" max-width="600">
                                <v-card elevation="4" class="pb-5">
                                    <template v-if="isLoginShieldEnabled">
                                        <v-toolbar short flat color="white">
                                            <v-toolbar-title>Disable LoginShield</v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text class="px-5">
                                            <p>You can disable LoginShield by making it unavailable as a login mechanism.</p>
                                            <p>This action is reversible. You can later re-enable LoginShield by simply returning here and switching it back on.</p>
                                            <p>While disabled, users who have activated LoginShield will revert to password login. If they don't remember their password, they can use access recovery.</p>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-btn elevation="4" class="red white--text" @click="disableLoginShield">
                                                Disable LoginShield
                                            </v-btn>
                                            <v-btn text class="grey--text" @click="dialogToggleEnabled = false">Cancel</v-btn>
                                            <v-spacer/>
                                        </v-card-actions>
                                    </template>
                                    <template v-if="!isLoginShieldEnabled">
                                        <v-toolbar short flat color="white">
                                            <v-toolbar-title>Enable LoginShield</v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text class="px-5">
                                            <p>You can enable LoginShield to make it available as a login mechanism.</p>
                                            <p>Users who have activated LoginShield will automatically resume using LoginShield when you enable it again.</p>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-btn elevation="4" class="blue white--text" @click="enableLoginShield">
                                                Enable LoginShield
                                            </v-btn>
                                            <v-btn text class="grey--text" @click="dialogToggleEnabled = false">Cancel</v-btn>
                                            <v-spacer/>
                                        </v-card-actions>
                                    </template>
                                </v-card>
                            </v-dialog>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <v-card tile elevation="2" v-if="isPermitSystemAdmin" class="mt-4 mb-2">
                    <v-toolbar flat short color="white">
                        <v-toolbar-title>Advanced</v-toolbar-title>
                        <v-spacer/>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    <font-awesome-icon :icon="['fas', 'id-badge']" class="indigo--text"/>
                                </span>
                            </template>
                            <span>Service administrators only</span>
                        </v-tooltip>
                    </v-toolbar>
                    <v-card-text class="text-start">
                        <p v-if="loginshieldRealmId">
                            LoginShield realm id: {{ loginshieldRealmId }}
                        </p>
                        <p v-if="isLoginShieldEnabled">
                            LoginShield mechanism is enabled.
                        </p>
                        <p v-if="isAutoUpdateEnabled">
                            LoginShield auto-update is enabled.
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile elevation="4" class="red white--text" @click="resetLoginShieldSetup">
                            Reset
                        </v-btn>
                    </v-card-actions>
                </v-card>

                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/RealmBar.vue';

export default {
    components: {
        AccessDeniedOverlay,
        RealmBar,
    },
    data: () => ({
        realm: null,
        forbiddenError: false,
        isEnabledCheckPending: false, // true while we're checking if LoginShield is enabled
        isEnabledCheckComplete: false, // will be set to true after we check if LoginShield is enabled
        loginshieldRealmId: null,
        icon: null,
        error: null,
        webauthz: null, // the request URI
        dialogToggleEnabled: false,
        dialogDisableLoginShield: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        ...mapGetters({
            isPermitSystemAdmin: 'isPermitSystemAdmin',
        }),
        displayName() {
            return this.realm?.display_name ?? this.realm?.alias ?? 'Unknown realm';
        },
        isLoginShieldEnabled() {
            return this.realm?.info?.mechanism_list?.includes('loginshield') ?? false;
        },
        isAutoUpdateEnabled() {
            return this.realm?.info?.loginshield?.auto_update ?? false;
        },
    },
    methods: {
        async loadRealm() {
            try {
                this.$store.commit('loading', { loadRealm: true });
                const result = await this.$client.account(this.$route.params.accountId).realm.get({ id: this.$route.params.realmId });
                console.log(`realm/loginshield.vue loadRealm result: ${JSON.stringify(result)}`);
                if (result) {
                    this.realm = result;
                }
            } catch (err) {
                console.error('realm/loginshield.vue loadRealm failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm', message });
            } finally {
                this.$store.commit('loading', { loadRealm: false });
            }
        },
        async loadInteraction() {
            try {
                this.$store.commit('loading', { loadInteraction: true });
                const result = await this.$client.main().interaction.get(this.$route.query.i);
                console.log(`realm/loginshield.vue loadInteraction result: ${JSON.stringify(result)}`);
                if (result?.type === 'setup_loginshield') {
                    await this.enableLoginShield();
                    // update loginshield with the realm logo and login url (because during initial setup we use the admin website url, but for actually using loginshield the realm url needs to be the login website url)
                    await this.syncRealmInfo();
                    // remove interaction id from query so if user reloads page we won't repeat the actions
                    this.$router.replace({ name: 'realm-loginshield', params: { accountId: this.$route.params.accountId, realmId: this.$route.params.realmId } });
                }
            } catch (err) {
                console.error('realm/loginshield.vue loadInteraction failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm', message });
            } finally {
                this.$store.commit('loading', { loadInteraction: false });
            }
        },
        async checkLoginShieldStatus() {
            // check if LoginShield is enabled
            try {
                this.isEnabledCheckPending = true;
                this.$store.commit('loading', { checkLoginShieldStatus: true });
                const result = await this.$client.account(this.$route.params.accountId).realm.getLoginShieldInfo({ id: this.$route.params.realmId });
                console.log(`realm/loginshield.vue checkLoginShieldStatus result: ${JSON.stringify(result)}`);
                if (result) {
                    this.loginshieldRealmId = result.loginshield_realm_id;
                    this.icon = result.icon;
                    this.error = result.error;
                    this.webauthz = result.webauthz;
                }
                // if user arrived with an interaction id, check if it's a loginshield setup interaction,
                // which means that if a loginshield realm id is present, we need to immediately enable loginshield
                if (this.$route.query.i) {
                    // wait for the interaction to load, and possibly for enabling loginshield, before
                    // we continue so that we don't flicker the UI with not-enabled and then enabled
                    await this.loadInteraction();
                }
            } catch (err) {
                console.error('realm/loginshield.vue checkLoginShieldStatus failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load LoginShield info', message });
            } finally {
                this.$store.commit('loading', { checkLoginShieldStatus: false });
                this.isEnabledCheckPending = false;
                this.isEnabledCheckComplete = true;
            }
        },
        async redirectToLoginShieldForSetup() {
            if (this.webauthz) {
                window.location = this.webauthz;
            } else {
                console.error('redirectToLoginShieldForSetup failed, missing webauthz request uri');
            }
        },
        async editRealm(request) {
            try {
                this.$store.commit('loading', { editRealm: true });
                const result = await this.$client.account(this.$route.params.accountId).realm.edit({ id: this.realm.id }, request);
                console.log(`editRealm result: ${JSON.stringify(result)}`);
                return result;
            } catch (err) {
                console.error('realm/loginshield.vue editRealm failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save changes', message });
                return { isEdited: false };
            } finally {
                this.$store.commit('loading', { editRealm: false });
            }
        },
        async disableLoginShield() {
            const result = await this.editRealm({ enable_mechanism: { loginshield: false } });
            this.dialogToggleEnabled = false;
            if (result.isEdited) {
                this.$bus.$emit('snackbar', { type: 'warn', headline: 'LoginShield is disabled' });
                this.loadRealm();
            } else {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save changes' });
            }
        },
        async enableLoginShield() {
            const result = await this.editRealm({ enable_mechanism: { loginshield: true } });
            this.dialogToggleEnabled = false;
            if (result.isEdited) {
                this.$bus.$emit('snackbar', { type: 'success', headline: 'LoginShield is enabled' });
                this.loadRealm();
            } else {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save changes' });
            }
        },
        async toggleAutoUpdate() {
            const newValue = !this.isAutoUpdateEnabled;
            const result = await this.editRealm({ loginshield_auto_update: newValue });
            if (result.isEdited) {
                this.$bus.$emit('snackbar', { type: 'success', headline: 'Saved changes' });
                this.loadRealm();
                if (newValue) {
                    // if user turned on auto update, start an update immediately to make sure LoginShield has whatever is the current realm info
                    this.syncRealmInfo();
                }
            } else {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save changes' });
            }
        },
        /**
         * If the realm has LoginShield auto update enabled, this will send the current
         * display name, logo, and login url to LoginShield
         */
        async syncRealmInfo() {
            try {
                this.$store.commit('loading', { syncRealmInfo: true });
                const result = await this.$client.account(this.$route.params.accountId).realm.updateLoginShieldRealmInfo({ id: this.$route.params.realmId }, { /* select: ['display_name', 'realm_image', 'login_url' ] */ });
                console.log(`realm/loginshield.vue syncRealmInfo result: ${JSON.stringify(result)}`);
                if (result?.isUpdated) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Updated LoginShield' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update LoginShield' });
                }
            } catch (err) {
                console.error('realm/loginshield.vue syncRealmInfo failed', err);
                const message = err.message ? err.message : null;
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to sync realm info to LoginShield', message });
            } finally {
                this.$store.commit('loading', { syncRealmInfo: false });
            }
        },
        async resetLoginShieldSetup() {
            const result = await this.editRealm({ action: 'reset-loginshield' });
            if (result.isEdited) {
                this.$bus.$emit('snackbar', { type: 'warn', headline: 'LoginShield is reset' });
                this.loadRealm();
                this.checkLoginShieldStatus();
            } else {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to save changes' });
            }
        },
    },
    mounted() {
        this.loadRealm();
        this.checkLoginShieldStatus();
    },
};
</script>
